import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The Good`}</strong></li>
      <li parentName="ul">{`MVP created under 1.5 weeks by using an Agile approach to the whole process`}</li>
      <li parentName="ul">{`An easy way to keep track of logs, projects, books and blog posts`}</li>
      <li parentName="ul">{`Basic achievements that can be unlocked`}</li>
      <li parentName="ul"><strong parentName="li">{`The Bad`}</strong></li>
      <li parentName="ul">{`Used Bulma.io but changed a lot of the CSS styling`}</li>
      <li parentName="ul">{`The achievements are based only on the number of posts with certain categories`}</li>
      <li parentName="ul">{`The documentation isn't created in the easiest way to read`}</li>
      <li parentName="ul"><strong parentName="li">{`The Ugly`}</strong></li>
      <li parentName="ul">{`The calendar needs the script to be added to the end of every page`}</li>
      <li parentName="ul">{`The UI looks a bit wonky on iPad screen resolutions`}</li>
      <li parentName="ul">{`Unable to click on the categories and tags`}</li>
    </ul>
    <h1 {...{
      "id": "how-it-all-came-to-be",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-it-all-came-to-be",
        "aria-label": "how it all came to be permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How it all came to be`}</h1>
    <p>{`Last year I participated in the #100DaysOfCode, initially, it was pretty easy to keep track of my progress but around day 30/40 the log was growing so big that I couldn't go back and check when or what I had done on a certain date.`}</p>
    <p>{`I've also seen how many new people began coding by taking the #100DaysOfCode and I wanted to give them the possibility to have an easy way to create a portfolio from day one, that's the reason why I choose Jekyll. Someone with little to no coding experience can easily set up their version of the site.`}</p>
    <p>{`Finally, I thought it would be good to have a way to see the progress at a glance, so the calendar view was added. The achievements was an attempt to add further gamification to the challenge to get people motivated throughout the challenge.`}</p>
    <h1 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h1>
    <p><strong parentName="p">{`Demo:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://100daysof.netlify.com/"
      }}>{`https://100daysof.netlify.com/`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/100daysof"
      }}>{`https://github.com/FabioRosado/100daysof`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      